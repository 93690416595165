<template>
  <b-container fluid>
    <iq-card>
        <template v-slot:headerTitle>
          <h4 class="card-title">{{ $t('fertilizerReport.received_report') }}</h4>
        </template>
      <template v-slot:body>
        <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
          <b-form  @submit.prevent="handleSubmit(register)" @reset.prevent="reset">
            <b-row>
              <b-col lg="6" sm="12">
                <ValidationProvider name="Godown" vid="godown_infos_id" rules="required|min_value:1">
                  <b-form-group
                    class="row"
                    label-cols-sm="3"
                    label-for="godown_infos_id"
                    slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                    {{ $t('fertilizerReport.godown') }} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      plain
                      v-model="search.godown_infos_id"
                      :options="godownInfoList"
                      id="godown_infos_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      >
                      <template v-slot:first>
                        <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col xs="12" sm="12" md="6">
                <ValidationProvider name="Program No" vid="program" rules="required">
                  <b-form-group
                    class="row"
                    label-cols-sm="3"
                    label-for="program"
                    slot-scope="{ valid, errors }"
                  >
                    <template v-slot:label>
                      {{$t('movement.program_no')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                        id="program_no"
                        v-model="search.program_no"
                        :state="errors[0] ? false : (valid ? true : null)"
                        :placeholder="$t('movement.program_no')"
                    ></b-form-input>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
            </b-row>
             <b-row>
              <b-col xl="6" lg="6" md="6" sm="12" xs="12">
                <ValidationProvider name="From Date" vid="from_date" rules="required">
                  <b-form-group
                      class="row"
                      label-cols-sm="3"
                      label-for="from_date"
                      slot-scope="{ valid, errors }"
                  >
                      <template v-slot:label>
                          {{$t('globalTrans.from_date')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-input class="form-control"
                          v-model="search.from_date"
                          placeholder="Select Date"
                          id="from_date"
                          :state="errors[0] ? false : (valid ? true : null)"
                      ></b-form-input>
                     <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col xl="6" lg="6" md="6" sm="12" xs="12">
                <ValidationProvider name="To Date" vid="to_date" rules="required">
                  <b-form-group
                      class="row"
                      label-cols-sm="3"
                      label-for="to_date"
                       slot-scope="{ valid, errors }"
                  >
                      <template v-slot:label>
                          {{$t('globalTrans.to_date')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-input class="form-control"
                          v-model="search.to_date"
                          placeholder="Select Date"
                          id="to_date"
                          :state="errors[0] ? false : (valid ? true : null)"
                      ></b-form-input>
                     <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
            </b-row>
            <b-row>
                <b-col xs="12" sm="12" md="12">
                  <b-button type="submit" variant="primary float-right">{{ $t('globalTrans.search')}}</b-button>
                </b-col>
            </b-row>
           </b-form>
        </ValidationObserver>
      </template>
    </iq-card>
      <b-row >
        <b-col md="12" v-if="showData">
          <b-overlay :show='loading'>
            <iq-card v-if='newDatas.length'>
              <template v-slot:body>
                  <b-row>
                    <b-col md="12">
                      <iq-card>
                        <template v-slot:headerTitle>
                          <h4 class="card-title">{{ $t('fertilizerReport.received_report') }}</h4>
                        </template>
                        <template v-slot:headerAction>
                          <b-button class="btn_add_new" @click="pdfExport">
                            <i class="far fa-file-pdf"></i>{{  $t('globalTrans.export_pdf') }}
                          </b-button>
                          <!-- <export-excel
                            class="btn btn_add_new"
                            :data="dataCustomize"
                            :fields= "json_fields"
                            :title="$t('fertilizerReport.received_report')"
                            worksheet="Product Stock Report Sheet"
                            name="received_report.xls">
                            <i class="far fa-file-excel"></i>{{ $t('globalTrans.export_excel') }}
                          </export-excel> -->
                        </template>
                        <template v-slot:body>
                          <b-overlay>
                            <b-row mt-5>
                              <b-col md="12" class="table-responsive">
                                <div style="border: 2px solid;margin:10px;">
                                  <b-row>
                                    <b-col>
                                      <list-report-head :base-url="seedFertilizerServiceBaseUrl" uri="fertilizer/config/report-heading/detail" :org-id="3">
                                        {{ $t('fertilizerReport.received_report') }}
                                      </list-report-head>
                                    </b-col>
                                  </b-row>
                                  <div class="text-center" style="padding-right:10px">
                                    <table style="width:100%;color:black;margin:20px;">
                                      <tr>
                                        <td align="left" style="width:15%">{{ $t('fertilizerReport.godown') }}</td>
                                        <td align="center" style="width:5%">:</td>
                                        <td align="left" style="width:15%">{{ ($i18n.locale === 'bn') ? search.godown_bn : search.godown }}</td>
                                        <td align="left" style="width:15%">{{ $t('fertilizerConfig.sales_center.sales_center') }}</td>
                                        <td align="center" style="width:5%">:</td>
                                        <td align="left" style="width:15%">{{ ($i18n.locale === 'bn') ? search.sale_name_bn : search.sale_name }}</td>
                                        <td align="left" style="width:15%">{{ $t('fertilizerConfig.fertilizer_name') }}</td>
                                        <td align="center" style="width:5%">:</td>
                                        <td align="left" style="width:15%">{{ ($i18n.locale === 'bn') ? search.fertilizer_name_bn : search.fertilizer_name }}</td>
                                      </tr>
                                      <tr>
                                        <td align="left" style="width:15%">{{ $t('movement.program_no') }}</td>
                                        <td align="center" style="width:5%">:</td>
                                        <td align="left" style="width:15%">{{search.program_no}}</td>
                                        <td align="left" style="width:15%">{{ $t('fertilizerProcurement.shipName') }}</td>
                                        <td align="center" style="width:5%">:</td>
                                        <td align="left" style="width:15%">{{ ($i18n.locale === 'bn') ? search.ship_bn : search.ship }}</td>
                                        <td align="left" style="width:15%">{{ $t('globalTrans.from_date') }}</td>
                                        <td align="center" style="width:5%">:</td>
                                        <td align="left" style="width:17%">{{search.from_date | dateFormat}}</td>
                                      </tr>
                                      <tr>
                                        <td align="left" style="width:15%">{{ $t('globalTrans.to_date') }}</td>
                                        <td align="center" style="width:5%">:</td>
                                        <td align="left" style="width:15%">{{search.to_date | dateFormat}}</td>
                                        <td align="left" style="width:15%"></td>
                                        <td align="center" style="width:5%"></td>
                                        <td align="left" style="width:15%"></td>
                                        <td align="left" style="width:15%"></td>
                                        <td align="center" style="width:5%"></td>
                                        <td align="left" style="width:15%"></td>
                                      </tr>
                                    </table>
                                  </div>
                                  <b-row>
                                    <div class="col text-right">
                                      <span class="text-right text-dark  font-weight-bold"> {{ $t('fertilizerReport.accountMayTons') }}</span>
                                    </div>
                                  </b-row>
                                  <slot v-for="(data, index) in newDatas">
                                    <b style="color:black" class="col-12" :key="index">{{($i18n.locale === 'bn') ? data.text_bn : data.text_en}}</b>
                                    <b-table-simple class="tg" hover bordered small caption-top  v-if="newDatas.length>0">
                                      <b-thead>
                                        <b-tr>
                                          <b-td class="tg-0lax">{{$t('globalTrans.sl_no')}}</b-td>
                                          <b-td class="tg-0lax">{{$t('movement.receivedDate')}} </b-td>
                                          <b-td class="tg-0pky">{{$t('movement.loriChalanNo') }}</b-td>
                                          <b-td class="tg-0lax" style="text-align:right !important">{{$t('movement.receivedAmount') }}</b-td>
                                        </b-tr>
                                      </b-thead>
                                      <b-tbody>
                                        <b-tr  v-for="(report, index1) in data.details" :key="index1">
                                          <b-td class="tg-0lax">{{$n(index1+1)}}</b-td>
                                          <b-td class="tg-0lax">{{ report.receive_date | dateFormat }}</b-td>
                                          <b-td class="tg-0lax">{{ report.lori_challan_no }}</b-td>
                                          <b-td class="tg-0lax"  style="text-align:right !important">{{ $n(report.received_amount) }}</b-td>
                                        </b-tr>
                                        <b-tr>
                                          <b-th class="tg-0lax" colspan="3"  style="text-align:right !important">{{ $t('fertilizerReport.total') }} </b-th>
                                          <b-th class="tg-0lax"  style="text-align:right !important">
                                            {{ $n(calculateTotal(data.details)) }}
                                          </b-th>
                                        </b-tr>
                                        <b-tr v-if="newDatas.length === (data.sl + 1)">
                                          <b-th class="tg-0lax" colspan="3" style="text-align:right !important">{{ $t('fertilizerReport.GrandTotalMTon') }} </b-th>
                                          <b-th class="tg-0lax" style="text-align:right !important">
                                            {{ $n(grandTotal(newDatas)) }}
                                          </b-th>
                                        </b-tr>
                                      </b-tbody>
                                    </b-table-simple>
                                  </slot>
                                </div>
                              </b-col>
                            </b-row>
                          </b-overlay>
                        </template>
                      </iq-card>
                    </b-col>
                  </b-row>
               </template>
            </iq-card>
            <div class="panel-body text-center mt-3" v-else>
              <h6 class="text-danger">{{ $t('globalTrans.noDataFound') }}</h6>
            </div>
          </b-overlay>
        </b-col>
      </b-row>
  </b-container>
</template>
<style type="text/css">
.tg  {border-collapse:collapse;border-spacing:0;}
.tg td{text-align: center !important; vertical-align: middle !important;}
.tg th{text-align: center !important; vertical-align: middle !important;}
</style>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { reportHeadingList, receivedReport } from '../../api/routes'
import ListReportHead from '.././ListReportHead.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import ExportPdf from './export-pdf_details'
import excel from 'vue-excel-export'
import flatpickr from 'flatpickr'
import Vue from 'vue'
Vue.use(excel)

const excelColumn = {
  Serial: 'serial',
  'Receive Date': 'receive_date',
  'Lori Challan No': 'lori_challan_no',
  'Delivered Amount': 'delivered_amount'
}
const excelColumnBn = {
  'কর্িমক নং': 'serial',
  'পর্াপ্ত তািরখ': 'receive_date',
  'লির চালান নং': 'lori_challan_no',
  'পর্াপ্ত পিরমাণ': 'delivered_amount'
}
export default {
  name: 'UiDataTable',
  components: {
    ListReportHead,
    ValidationObserver,
    ValidationProvider
  },
  data () {
    return {
      showData: false,
      search: {
        godown_infos_id: 0,
        program_no: '',
        from_date: '',
        to_date: '',
        transport_agent_name: '',
        transport_agent_name_bn: ''
      },
      datas: [],
      newDatas: [],
      seedFertilizerServiceBaseUrl: seedFertilizerServiceBaseUrl,
      reportHeadingList: reportHeadingList,
      loading: false
    }
  },
  computed: {
    json_fields: function () {
      return this.$i18n.locale === 'bn' ? excelColumnBn : excelColumn
    },
    dataCustomize () {
      var serial = 0
      const listContractor = this.datas.map(item => {
        serial += 1
        return Object.assign({}, item, {
          serial: this.$n(serial)
        })
      })
      listContractor.push({
        serial: '',
        receive_date: '',
        lori_challan_no: this.$t('movement.total')
      })
      return listContractor
    },
    fertilizerNameList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.fertilizerNameList.filter(item => item.status === 1)
    },
    godownInfoList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.godownInfoList.filter(item => item.status === 1)
    },
    transportAgentList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.transportAgentList.filter(item => item.status === 1)
    }
  },
  mounted () {
    flatpickr('#from_date', {})
    flatpickr('#to_date', {})
    core.index()
  },
  methods: {
    grandTotal (data) {
      let total = 0
      data.forEach((item, index) => {
        item.details.forEach((item1, index) => {
          total += item1.received_amount
        })
      })
      return total
    },
    calculateTotal (report) {
       return report.map(r => r.received_amount).reduce((a, c) => {
        a = a ? parseInt(a) : 0
        c = c ? parseInt(c) : 0
        return a + c
      })
    },
    async register () {
      this.showData = true
      this.loadData()
    },
    async loadData () {
      this.loading = true
      await RestApi.getData(seedFertilizerServiceBaseUrl, receivedReport, this.search).then(response => {
        if (response.success) {
          if (response.data) {
            const agent = []
            let sl = 0
            this.transportAgentList.forEach((item, index) => {
              const data = response.data.filter(res => res.transport_agent_id === item.value)
              if (data.length > 0) {
                item.sl = sl
                agent[sl] = item
                agent[sl].details = data
                sl++
              }
            })
            this.newDatas = agent
            // this.search.transport_agent_name_bn = this.datas[0].transport_agent.company_agent_name_bn
            // this.search.transport_agent_name = this.datas[0].transport_agent.company_agent_name
            this.search.godown = response.godown.name
            this.search.godown_bn = response.godown.name_bn
            this.search.sales_center_id = response.godown.sales_center_id
            this.search.ship = response.lc.ship_name
            this.search.ship_bn = response.lc.ship_name_bn
            this.search.fertilizer_id = response.lc.fertilizer_id
            const fartilizerObj = this.fertilizerNameList.find(item => item.value === this.search.fertilizer_id)
            this.search.fertilizer_name = typeof fartilizerObj !== 'undefined' ? fartilizerObj.text_en : ''
            this.search.fertilizer_name_bn = typeof fartilizerObj !== 'undefined' ? fartilizerObj.text_bn : ''
            const saleOfficeList = this.$store.state.commonObj.officeList.find(item => item.value === this.search.sales_center_id)
            this.search.sale_name = typeof saleOfficeList !== 'undefined' ? saleOfficeList.text_en : ''
            this.search.sale_name_bn = typeof saleOfficeList !== 'undefined' ? saleOfficeList.text_bn : ''
            this.showData = true
          }
        } else {
          this.newDatas = []
        }
        this.loading = false
      })
    },
    pdfExport () {
      const reportTitle = this.$t('fertilizerReport.received_report')
      ExportPdf.exportPdfDetails(seedFertilizerServiceBaseUrl, '/fertilizer/config/report-heading/detail', 3, reportTitle, this.newDatas, this, this.search)
    }
  }
}
</script>
<style scoped>
 .container {
   display: flex;
   margin-bottom: 15px;
   justify-content: center;
 }
 .report-name {
   text-align: center;
 }
 .org-name {
  text-align: center;
 }
 .org-address {
   text-align: center;
 }
 .main-title {
   padding: 10px;
 }
 .project-name {
   text-align: center;
   font-weight: bold;
 }
</style>
